import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EquipmentsService {

  constructor(private http: HttpClient) { }

  listEquipments(affiliate: string, fpso: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/${affiliate}/${fpso}/admin-configs`, { });
  }

  downloadFile(affiliate: string, fpso: string): Observable<any> {
    const params = new HttpParams();
    return this.http.get(`${environment.apiUrl}/${affiliate}/${fpso}/admin-configs/kz/download`, {params, responseType: 'blob', observe: 'response'})
  }

  updateConfig(affiliate: string, fpso: string, config:any): Observable<any>  {
    return this.http.put(`${environment.apiUrl}/${affiliate}/${fpso}/admin-configs/update`, config);
  }
}
