import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AdminComponent } from './admin.component';
import { TabSelectorModule } from '../../components/tab-selector/tab-selector.module';

const routes: Routes = [
  { path: '', component: AdminComponent }
];

@NgModule({
  declarations: [AdminComponent],
  imports: [
    CommonModule,
    FormsModule,
    TabSelectorModule,
    RouterModule.forChild(routes)
  ],
  exports: [AdminComponent]
})
export class AdminModule { }
