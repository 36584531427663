import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap, Subscription } from 'rxjs';
import { AppSandbox } from '../../app.sandbox';
import { EquipmentsService } from '../../services/equipments/equipments.service';
import { FAVORITE_ASSET_STORAGE_KEY, ROLES_STORAGE_KEY } from '../app';
import { EncryptionService } from '../../services/encryption/encryption.service';
import { EquipmentCollection } from "../../types/equipment-type.enum";
import { cloneObject } from "../../utils/object-manipulation/object-manipulation";
import { ToastService } from "../../services/toaster/toaster.service";
import { downloadFileFromHttpResponse } from "../../utils/api/api";
import { FrontConfigsService } from "../../services/front-configs/front-configs.service";

@Component({
  selector: 'co2-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {

  listEquipments: EquipmentCollection[] = [];
  originalEquipments: any;
  disabled = true;
  actionEdit = false;
  list = false;
  favoriteAsset = JSON.parse(localStorage.getItem(FAVORITE_ASSET_STORAGE_KEY) ?? '{}');
  assets!: string[];
  fpsoTabs: any;
  activeFpso: string = '';
  subscription!: Subscription;
  accordionStates: boolean[] = [];
  actionEditIndex: number | null = null;
  decryptedRoles:any;
  isAdmin:boolean = false;
  selectedFpso:string='';
  isSaveDisabled = false;
  containers: number[] = [];
  equipmentsInContainers: number = 4;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sb: AppSandbox,
    private equipmentService: EquipmentsService,
    private encryptionService: EncryptionService,
    private toastService: ToastService,
    private frontConfigsService: FrontConfigsService
  ) {
    this.decryptedRoles = this.encryptionService.decryptData(localStorage.getItem(ROLES_STORAGE_KEY));
    if(this.decryptedRoles) this.isAdmin = this.decryptedRoles.includes('ADMIN');
  }

  ngOnInit() {
    this.subscription = this.frontConfigsService.getFpsosByAffiliate(this.favoriteAsset.affiliate).pipe(
      concatMap(sites => {
        this.assets = sites;
        return this.route.params;
      })
    ).subscribe(params => {
      this.initializeComponent()
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  initializeComponent() {
    if(this.selectedFpso) {
      this.activeFpso = this.selectedFpso;
    } else {
      this.activeFpso = this.sb.getActiveFpso();
    }
    this.fpsoTabs = this.assets.map(item => ({
      id: item,
      label: item
    }));
    this.getEquipments();
  }

  async getEquipments() {
    this.listEquipments = [];
    this.list = false;
    await this.equipmentService.listEquipments('ANGOLA', this.activeFpso).subscribe(data => {

        this.list = true;
        this.listEquipments = data;
        if(data.length) {
        let numberOfContainers = Math.ceil(this.listEquipments[0]?.equipments.length / this.equipmentsInContainers);
        this.containers = [...Array(numberOfContainers).keys()];
        this.originalEquipments = cloneObject(data);
      }
    });
  }


  cancelEdits(index: number): void {
    // Restaurer les valeurs originales
    this.listEquipments = cloneObject(this.originalEquipments);
    this.actionEditIndex = null;
    this.actionEdit = ! this.actionEdit;
  }

  getObjectKeys(obj: object): string[] {
    return Object.keys(obj);
  }

  editInput(index: number): void {
    // Toggle the edit mode for the item at the given index
    this.actionEditIndex = index; // Set the index of the item being edited
    this.actionEdit = !this.actionEdit;
  }

  onSelectTab(fpsoName: string): void {
    this.activeFpso = fpsoName;
    this.selectedFpso= fpsoName;
    const affiliate = this.sb.getActiveAffiliate();
    this.listEquipments = [];
    this.router.navigate([affiliate, fpsoName, 'config']);
  }

  toggleAccordion(index: number): void {
    this.accordionStates[index] = !this.accordionStates[index];
  }

  isActive(index: number): boolean {
    return this.accordionStates[index];
  }

  getFileNameFromPath(path: string): string {
    return path.split('/').pop() ?? 'downloaded_file';
  }

  downloadFile(): void {
    this.equipmentService.downloadFile(this.sb.getActiveAffiliate(), this.activeFpso).subscribe({
      next: (response) => downloadFileFromHttpResponse(response),
      error: (err) => console.error('File download failed:', err)
    });
  }

  updateConfig(config: any) {
      const updatePayload = {
        id: config.id,
        label: config.label,
        params: config.params
      };

      this.equipmentService.updateConfig(this.sb.getActiveAffiliate(),this.activeFpso, updatePayload)
        .subscribe({
          next: (response) => {

            this.actionEditIndex = null;
            this.actionEdit = ! this.actionEdit;
            this.toastService.showToast('Update success');
          },
          error: (error) => {
            console.error('Update error:', error);
            this.toastService.showToast('Update error', 'error');
          }
        });
  }

  saveEdits(equipment: any, index:number): void {
    this.updateConfig(equipment);
  }

  validateNumberInput(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    const currentValue = (event.target as HTMLInputElement).value;

    if (!inputChar.match(/[0-9.]/) || (inputChar === '.' && currentValue.includes('.'))) {
      event.preventDefault();
    }
  }

  checkEmptyInput(value: string) {
    const regex = /^\d+(\.\d+)?$/;
    this.isSaveDisabled = !regex.test(value);
  }

}
